<template>
  <div>
    <el-select
      v-model="select"
      filterable
      remote
      reserve-keyword
      placeholder="Введите адрес для поиска"
      :remote-method="remoteMethod"
      @change="changeCoordinates"
      v-loading="isLoading"
      style="width: 100%;"
      :popper-append-to-body="false"
    >
        <el-option
          v-for="(item, key) in options"
          :key="Date.now() + key"
          :label="item.value"
          :value="item"></el-option>
    </el-select>
  </div>
</template>

<script>
import { APIClient } from '@/core/infrastructure/api/APIClient'
import { FIASAddressServiceAPI } from '@/core/infrastructure/api/modules/FIASAddressServiceAPI'

export default {
  name: 'enterAddress',
  props: {
    address: {
      type: String
    },
    defaultValue: {
      type: String
    }
  },
  data () {
    return {
      select: null,
      options: [],
      isLoading: false
    }
  },
  async mounted () {
    let response = await APIClient.shared.request(
      new FIASAddressServiceAPI.GetDaDataInfo(this.defaultValue + ' ' + this.address)
    )
    if (response.length === 1 && response[0].result) {
      let item = {
        value: response[0].result,
        data: response[0]
      }
      this.options.splice(0, 0, item)
      this.select = item
      this.changeCoordinates(item)
    } else if (this.address) {
      this.select = this.address
      await this.remoteMethod(this.address)
    }
  },
  methods: {
    changeCoordinates (newValue) {
      this.$emit('change-coordinates', { x: newValue.data.geo_lon, y: newValue.data.geo_lat })
      this.$emit('change-cs', 4326)
    },
    async remoteMethod (query) {
      let me = this
      if (this.defaultValue) {
        query = this.defaultValue + ' ' + query
      }
      this.$daData
        .post(
          `https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address`,
          { query: query },
          { hideNotification: true }
        )
        .then((response) => {
          me.options.splice(0, me.options.length)
          response.data.suggestions.forEach((item) => {
            me.options.splice(me.options.length, 0, item)
          })
        })
    }
  }
}
</script>

<style scoped>
.el-select /deep/ .el-select-dropdown {
  max-width: calc(100% - 10px)
}
</style>
